const data = [
    {
        id: 1,
        avatar: 'https://robohash.org/delenitivoluptatesfugit.png?size=50x50&set=set1',
        name: 'Shirline Birth',
        sex: 'Female',
        birth_date: '28/09/2022',
        birth_place: 'Russia',
    },
    {
        id: 2,
        avatar: 'https://robohash.org/nemovelut.png?size=50x50&set=set1',
        name: 'Korie Jewsbury',
        sex: 'Female',
        birth_date: '02/08/2022',
        birth_place: 'Armenia',
    },
    {
        id: 3,
        avatar: 'https://robohash.org/estconsecteturrepellendus.png?size=50x50&set=set1',
        name: 'Hurleigh Moors',
        sex: 'Male',
        birth_date: '02/04/2022',
        birth_place: 'United States',
    },
    {
        id: 4,
        avatar: 'https://robohash.org/eabeataeenim.png?size=50x50&set=set1',
        name: 'Bessie Worpole',
        sex: 'Female',
        birth_date: '15/01/2023',
        birth_place: 'United States',
    },
    {
        id: 5,
        avatar: 'https://robohash.org/etvoluptatumplaceat.png?size=50x50&set=set1',
        name: 'Rowen Cathenod',
        sex: 'Male',
        birth_date: '12/08/2022',
        birth_place: 'Colombia',
    },
    {
        id: 6,
        avatar: 'https://robohash.org/voluptaspariaturvoluptas.png?size=50x50&set=set1',
        name: 'Else Lumley',
        sex: 'Female',
        birth_date: '13/04/2022',
        birth_place: 'Iraq',
    },
    {
        id: 7,
        avatar: 'https://robohash.org/molestiassintet.png?size=50x50&set=set1',
        name: 'Benjy Nosworthy',
        sex: 'Male',
        birth_date: '08/02/2022',
        birth_place: 'Russia',
    },
    {
        id: 8,
        avatar: 'https://robohash.org/quasetnihil.png?size=50x50&set=set1',
        name: 'Ginger Fowden',
        sex: 'Female',
        birth_date: '16/10/2022',
        birth_place: 'Japan',
    },
    {
        id: 9,
        avatar: 'https://robohash.org/autemveritatisvoluptatem.png?size=50x50&set=set1',
        name: 'Pavlov Blose',
        sex: 'Male',
        birth_date: '02/05/2022',
        birth_place: 'Moldova',
    },
    {
        id: 10,
        avatar: 'https://robohash.org/quaeratmodiut.png?size=50x50&set=set1',
        name: 'Frederica Deplacido',
        sex: 'Female',
        birth_date: '15/11/2022',
        birth_place: 'China',
    },
    {
        id: 11,
        avatar: 'https://robohash.org/errornostrumquia.png?size=50x50&set=set1',
        name: 'Zora Campaigne',
        sex: 'Female',
        birth_date: '26/01/2023',
        birth_place: 'Egypt',
    },
    {
        id: 12,
        avatar: 'https://robohash.org/atquiexpedita.png?size=50x50&set=set1',
        name: 'Maximo Kerford',
        sex: 'Male',
        birth_date: '19/03/2022',
        birth_place: 'Russia',
    },
    {
        id: 13,
        avatar: 'https://robohash.org/uttemporeconsequatur.png?size=50x50&set=set1',
        name: 'Druci Fancott',
        sex: 'Female',
        birth_date: '26/04/2022',
        birth_place: 'Iran',
    },
    {
        id: 14,
        avatar: 'https://robohash.org/facererecusandaesunt.png?size=50x50&set=set1',
        name: 'Gertrud Kneesha',
        sex: 'Female',
        birth_date: '30/10/2022',
        birth_place: 'Poland',
    },
    {
        id: 15,
        avatar: 'https://robohash.org/autfugiatquod.png?size=50x50&set=set1',
        name: 'Calvin Seif',
        sex: 'Male',
        birth_date: '27/01/2023',
        birth_place: 'France',
    },
    {
        id: 16,
        avatar: 'https://robohash.org/sitquiahic.png?size=50x50&set=set1',
        name: 'Bevon Szanto',
        sex: 'Male',
        birth_date: '11/12/2022',
        birth_place: 'Brazil',
    },
    {
        id: 17,
        avatar: 'https://robohash.org/omnisestvoluptates.png?size=50x50&set=set1',
        name: 'Lexine Po',
        sex: 'Female',
        birth_date: '28/02/2022',
        birth_place: 'Philippines',
    },
    {
        id: 18,
        avatar: 'https://robohash.org/sitestin.png?size=50x50&set=set1',
        name: 'Anton Moreton',
        sex: 'Male',
        birth_date: '22/03/2022',
        birth_place: 'Dominican Republic',
    },
    {
        id: 19,
        avatar: 'https://robohash.org/similiqueremeligendi.png?size=50x50&set=set1',
        name: 'Gian Othick',
        sex: 'Male',
        birth_date: '18/07/2022',
        birth_place: 'Peru',
    },
    {
        id: 20,
        avatar: 'https://robohash.org/utconsequatursint.png?size=50x50&set=set1',
        name: 'Bobinette Duro',
        sex: 'Female',
        birth_date: '13/06/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 21,
        avatar: 'https://robohash.org/remhicid.png?size=50x50&set=set1',
        name: 'Antoinette Schwand',
        sex: 'Female',
        birth_date: '10/09/2022',
        birth_place: 'China',
    },
    {
        id: 22,
        avatar: 'https://robohash.org/delectusveniamdebitis.png?size=50x50&set=set1',
        name: 'Candra Kynd',
        sex: 'Female',
        birth_date: '01/07/2022',
        birth_place: 'Philippines',
    },
    {
        id: 23,
        avatar: 'https://robohash.org/porroutratione.png?size=50x50&set=set1',
        name: 'Fonz Ness',
        sex: 'Male',
        birth_date: '23/07/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 24,
        avatar: 'https://robohash.org/providentsedfugiat.png?size=50x50&set=set1',
        name: 'Shaun Minero',
        sex: 'Female',
        birth_date: '03/09/2022',
        birth_place: 'Japan',
    },
    {
        id: 25,
        avatar: 'https://robohash.org/doloresmollitiaest.png?size=50x50&set=set1',
        name: 'Karolina Udell',
        sex: 'Female',
        birth_date: '09/04/2022',
        birth_place: 'Brazil',
    },
    {
        id: 26,
        avatar: 'https://robohash.org/velitvoluptatemsint.png?size=50x50&set=set1',
        name: 'Neda Hayward',
        sex: 'Female',
        birth_date: '31/07/2022',
        birth_place: 'Venezuela',
    },
    {
        id: 27,
        avatar: 'https://robohash.org/dolorvoluptatibusincidunt.png?size=50x50&set=set1',
        name: 'Brooks Portsmouth',
        sex: 'Male',
        birth_date: '17/03/2022',
        birth_place: 'China',
    },
    {
        id: 28,
        avatar: 'https://robohash.org/quipossimusvero.png?size=50x50&set=set1',
        name: 'Chrisse Strutton',
        sex: 'Male',
        birth_date: '31/08/2022',
        birth_place: 'Malaysia',
    },
    {
        id: 29,
        avatar: 'https://robohash.org/sequivoluptatemquis.png?size=50x50&set=set1',
        name: 'Grace Girdlestone',
        sex: 'Male',
        birth_date: '23/11/2022',
        birth_place: 'Peru',
    },
    {
        id: 30,
        avatar: 'https://robohash.org/saepebeataeanimi.png?size=50x50&set=set1',
        name: 'Leigh Kimmings',
        sex: 'Female',
        birth_date: '04/02/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 31,
        avatar: 'https://robohash.org/debitisvoluptatevoluptas.png?size=50x50&set=set1',
        name: 'Weider de la Tremoille',
        sex: 'Male',
        birth_date: '25/08/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 32,
        avatar: 'https://robohash.org/nequeestad.png?size=50x50&set=set1',
        name: 'Ray Abercromby',
        sex: 'Male',
        birth_date: '17/10/2022',
        birth_place: 'Mexico',
    },
    {
        id: 33,
        avatar: 'https://robohash.org/veritatistemporatemporibus.png?size=50x50&set=set1',
        name: 'Corbin Durward',
        sex: 'Male',
        birth_date: '08/01/2023',
        birth_place: 'Serbia',
    },
    {
        id: 34,
        avatar: 'https://robohash.org/cumvoluptatesdignissimos.png?size=50x50&set=set1',
        name: 'Hank Baitson',
        sex: 'Male',
        birth_date: '28/01/2023',
        birth_place: 'France',
    },
    {
        id: 35,
        avatar: 'https://robohash.org/architectolaborumerror.png?size=50x50&set=set1',
        name: 'Sarita Beetham',
        sex: 'Female',
        birth_date: '27/07/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 36,
        avatar: 'https://robohash.org/quasivoluptascorrupti.png?size=50x50&set=set1',
        name: 'Jonathon Manketell',
        sex: 'Male',
        birth_date: '30/12/2022',
        birth_place: 'China',
    },
    {
        id: 37,
        avatar: 'https://robohash.org/exercitationemteneturquia.png?size=50x50&set=set1',
        name: 'Natalina Knutsen',
        sex: 'Polygender',
        birth_date: '23/12/2022',
        birth_place: 'Philippines',
    },
    {
        id: 38,
        avatar: 'https://robohash.org/rerumautaspernatur.png?size=50x50&set=set1',
        name: 'Mitchael Philot',
        sex: 'Male',
        birth_date: '16/01/2023',
        birth_place: 'Syria',
    },
    {
        id: 39,
        avatar: 'https://robohash.org/ametaliaslaborum.png?size=50x50&set=set1',
        name: 'Cati Vlasov',
        sex: 'Female',
        birth_date: '20/02/2022',
        birth_place: 'Liberia',
    },
    {
        id: 40,
        avatar: 'https://robohash.org/facilisquisquamofficiis.png?size=50x50&set=set1',
        name: 'Mattias Lardeur',
        sex: 'Male',
        birth_date: '12/12/2022',
        birth_place: 'Morocco',
    },
    {
        id: 41,
        avatar: 'https://robohash.org/quiquasinisi.png?size=50x50&set=set1',
        name: 'Marge Sumsion',
        sex: 'Female',
        birth_date: '17/04/2022',
        birth_place: 'Brazil',
    },
    {
        id: 42,
        avatar: 'https://robohash.org/ducimusadest.png?size=50x50&set=set1',
        name: 'Darnall Kamenar',
        sex: 'Male',
        birth_date: '15/10/2022',
        birth_place: 'Vietnam',
    },
    {
        id: 43,
        avatar: 'https://robohash.org/repudiandaeettempore.png?size=50x50&set=set1',
        name: 'Chris Janczyk',
        sex: 'Male',
        birth_date: '04/11/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 44,
        avatar: 'https://robohash.org/nemoipsamet.png?size=50x50&set=set1',
        name: 'Astra Gilhouley',
        sex: 'Female',
        birth_date: '16/04/2022',
        birth_place: 'Afghanistan',
    },
    {
        id: 45,
        avatar: 'https://robohash.org/auteminvero.png?size=50x50&set=set1',
        name: 'Rossy Droghan',
        sex: 'Male',
        birth_date: '17/08/2022',
        birth_place: 'Philippines',
    },
    {
        id: 46,
        avatar: 'https://robohash.org/exercitationemutminima.png?size=50x50&set=set1',
        name: 'Amie Jouhning',
        sex: 'Female',
        birth_date: '23/07/2022',
        birth_place: 'Afghanistan',
    },
    {
        id: 47,
        avatar: 'https://robohash.org/idutearum.png?size=50x50&set=set1',
        name: 'Jemmie Daddow',
        sex: 'Female',
        birth_date: '18/09/2022',
        birth_place: 'Philippines',
    },
    {
        id: 48,
        avatar: 'https://robohash.org/rerumutvoluptatum.png?size=50x50&set=set1',
        name: 'Mariellen Grimolbie',
        sex: 'Female',
        birth_date: '28/07/2022',
        birth_place: 'China',
    },
    {
        id: 49,
        avatar: 'https://robohash.org/animietamet.png?size=50x50&set=set1',
        name: 'Vite Arlow',
        sex: 'Male',
        birth_date: '05/05/2022',
        birth_place: 'China',
    },
    {
        id: 50,
        avatar: 'https://robohash.org/nonremmolestiae.png?size=50x50&set=set1',
        name: 'Emlyn Corona',
        sex: 'Male',
        birth_date: '11/08/2022',
        birth_place: 'China',
    },
    {
        id: 51,
        avatar: 'https://robohash.org/suntdoloremasperiores.png?size=50x50&set=set1',
        name: 'Burke Broadnicke',
        sex: 'Male',
        birth_date: '24/01/2023',
        birth_place: 'China',
    },
    {
        id: 52,
        avatar: 'https://robohash.org/explicabooditaut.png?size=50x50&set=set1',
        name: 'Garrot Heed',
        sex: 'Male',
        birth_date: '18/09/2022',
        birth_place: 'Argentina',
    },
    {
        id: 53,
        avatar: 'https://robohash.org/quiadoloremquevoluptate.png?size=50x50&set=set1',
        name: 'Legra Leathlay',
        sex: 'Female',
        birth_date: '21/07/2022',
        birth_place: 'China',
    },
    {
        id: 54,
        avatar: 'https://robohash.org/delectusetvero.png?size=50x50&set=set1',
        name: 'Melina Balharry',
        sex: 'Bigender',
        birth_date: '30/03/2022',
        birth_place: 'Argentina',
    },
    {
        id: 55,
        avatar: 'https://robohash.org/omnisquisquamqui.png?size=50x50&set=set1',
        name: 'Carrol Fransson',
        sex: 'Male',
        birth_date: '11/09/2022',
        birth_place: 'Czech Republic',
    },
    {
        id: 56,
        avatar: 'https://robohash.org/illuminminus.png?size=50x50&set=set1',
        name: 'Branden Manon',
        sex: 'Male',
        birth_date: '17/09/2022',
        birth_place: 'Netherlands',
    },
    {
        id: 57,
        avatar: 'https://robohash.org/namipsumeveniet.png?size=50x50&set=set1',
        name: 'Merrick de Mendoza',
        sex: 'Male',
        birth_date: '05/05/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 58,
        avatar: 'https://robohash.org/namessevoluptatum.png?size=50x50&set=set1',
        name: 'Lammond Needs',
        sex: 'Male',
        birth_date: '28/02/2022',
        birth_place: 'China',
    },
    {
        id: 59,
        avatar: 'https://robohash.org/dignissimosaspernaturvel.png?size=50x50&set=set1',
        name: 'Hinze Huckle',
        sex: 'Male',
        birth_date: '02/02/2022',
        birth_place: 'East Timor',
    },
    {
        id: 60,
        avatar: 'https://robohash.org/expeditaquoprovident.png?size=50x50&set=set1',
        name: 'Nappy Becerra',
        sex: 'Male',
        birth_date: '16/12/2022',
        birth_place: 'Philippines',
    },
    {
        id: 61,
        avatar: 'https://robohash.org/facilispariaturmaiores.png?size=50x50&set=set1',
        name: 'Matthiew Van Der Hoog',
        sex: 'Male',
        birth_date: '07/10/2022',
        birth_place: 'Vietnam',
    },
    {
        id: 62,
        avatar: 'https://robohash.org/doloremvoluptatemillo.png?size=50x50&set=set1',
        name: 'Laughton Boxill',
        sex: 'Bigender',
        birth_date: '28/11/2022',
        birth_place: 'Portugal',
    },
    {
        id: 63,
        avatar: 'https://robohash.org/utvoluptassint.png?size=50x50&set=set1',
        name: 'Reese Swatridge',
        sex: 'Male',
        birth_date: '12/03/2022',
        birth_place: 'Canada',
    },
    {
        id: 64,
        avatar: 'https://robohash.org/perspiciatisullamofficiis.png?size=50x50&set=set1',
        name: 'Deeann Ruddoch',
        sex: 'Female',
        birth_date: '27/01/2023',
        birth_place: 'Philippines',
    },
    {
        id: 65,
        avatar: 'https://robohash.org/laboriosamquisunt.png?size=50x50&set=set1',
        name: 'Courtney Josuweit',
        sex: 'Female',
        birth_date: '11/05/2022',
        birth_place: 'China',
    },
    {
        id: 66,
        avatar: 'https://robohash.org/etexplicaboimpedit.png?size=50x50&set=set1',
        name: 'Erinn Sapp',
        sex: 'Female',
        birth_date: '26/10/2022',
        birth_place: 'Thailand',
    },
    {
        id: 67,
        avatar: 'https://robohash.org/dictapariaturrerum.png?size=50x50&set=set1',
        name: 'Rafe Tomblin',
        sex: 'Male',
        birth_date: '18/01/2023',
        birth_place: 'Peru',
    },
    {
        id: 68,
        avatar: 'https://robohash.org/quimolestiascum.png?size=50x50&set=set1',
        name: 'Meg Casacchia',
        sex: 'Female',
        birth_date: '25/09/2022',
        birth_place: 'China',
    },
    {
        id: 69,
        avatar: 'https://robohash.org/verotemporibuslaudantium.png?size=50x50&set=set1',
        name: 'Johnna Tanti',
        sex: 'Female',
        birth_date: '13/04/2022',
        birth_place: 'Brazil',
    },
    {
        id: 70,
        avatar: 'https://robohash.org/deseruntliberonatus.png?size=50x50&set=set1',
        name: 'Aylmer Bum',
        sex: 'Male',
        birth_date: '21/09/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 71,
        avatar: 'https://robohash.org/cumqueessevoluptatem.png?size=50x50&set=set1',
        name: 'Letitia Free',
        sex: 'Female',
        birth_date: '30/12/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 72,
        avatar: 'https://robohash.org/nonvoluptatemmolestias.png?size=50x50&set=set1',
        name: 'Lew Vasyunichev',
        sex: 'Male',
        birth_date: '25/11/2022',
        birth_place: 'Serbia',
    },
    {
        id: 73,
        avatar: 'https://robohash.org/molestiaevelmollitia.png?size=50x50&set=set1',
        name: 'Melisandra Jewel',
        sex: 'Female',
        birth_date: '31/08/2022',
        birth_place: 'Philippines',
    },
    {
        id: 74,
        avatar: 'https://robohash.org/doloremquenisiminima.png?size=50x50&set=set1',
        name: 'Randolph Macallam',
        sex: 'Male',
        birth_date: '22/03/2022',
        birth_place: 'Poland',
    },
    {
        id: 75,
        avatar: 'https://robohash.org/liberocorporisanimi.png?size=50x50&set=set1',
        name: 'Fidel Gidden',
        sex: 'Male',
        birth_date: '06/12/2022',
        birth_place: 'Tanzania',
    },
    {
        id: 76,
        avatar: 'https://robohash.org/possimusetexcepturi.png?size=50x50&set=set1',
        name: 'Carmelita Di Domenico',
        sex: 'Female',
        birth_date: '10/10/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 77,
        avatar: 'https://robohash.org/maioresculpafuga.png?size=50x50&set=set1',
        name: 'Carl Danell',
        sex: 'Male',
        birth_date: '12/08/2022',
        birth_place: 'Philippines',
    },
    {
        id: 78,
        avatar: 'https://robohash.org/quominimaqui.png?size=50x50&set=set1',
        name: 'Clayborn Grimes',
        sex: 'Male',
        birth_date: '16/10/2022',
        birth_place: 'Belarus',
    },
    {
        id: 79,
        avatar: 'https://robohash.org/laborumquoid.png?size=50x50&set=set1',
        name: 'Vivienne Cohalan',
        sex: 'Female',
        birth_date: '10/03/2022',
        birth_place: 'Sierra Leone',
    },
    {
        id: 80,
        avatar: 'https://robohash.org/errorsedet.png?size=50x50&set=set1',
        name: 'Robina Tripony',
        sex: 'Female',
        birth_date: '03/03/2022',
        birth_place: 'China',
    },
    {
        id: 81,
        avatar: 'https://robohash.org/etquodculpa.png?size=50x50&set=set1',
        name: 'Cornall Craisford',
        sex: 'Male',
        birth_date: '12/01/2023',
        birth_place: 'Brazil',
    },
    {
        id: 82,
        avatar: 'https://robohash.org/adipiscisintperferendis.png?size=50x50&set=set1',
        name: 'Val MacCafferky',
        sex: 'Genderfluid',
        birth_date: '12/04/2022',
        birth_place: 'Vietnam',
    },
    {
        id: 83,
        avatar: 'https://robohash.org/quisomnisaliquam.png?size=50x50&set=set1',
        name: 'Pattie Kochel',
        sex: 'Female',
        birth_date: '16/02/2022',
        birth_place: 'Philippines',
    },
    {
        id: 84,
        avatar: 'https://robohash.org/estoccaecatiet.png?size=50x50&set=set1',
        name: 'Danila Diche',
        sex: 'Polygender',
        birth_date: '26/02/2022',
        birth_place: 'Bulgaria',
    },
    {
        id: 85,
        avatar: 'https://robohash.org/enimquihic.png?size=50x50&set=set1',
        name: 'Rosalinda Sabate',
        sex: 'Female',
        birth_date: '01/01/2023',
        birth_place: 'France',
    },
    {
        id: 86,
        avatar: 'https://robohash.org/molestiaspraesentiumrepellendus.png?size=50x50&set=set1',
        name: 'Tadeas Beresford',
        sex: 'Male',
        birth_date: '17/09/2022',
        birth_place: 'North Korea',
    },
    {
        id: 87,
        avatar: 'https://robohash.org/porroearumlaudantium.png?size=50x50&set=set1',
        name: 'Gerianna Sandland',
        sex: 'Female',
        birth_date: '26/08/2022',
        birth_place: 'Canada',
    },
    {
        id: 88,
        avatar: 'https://robohash.org/rationedoloremqueaccusantium.png?size=50x50&set=set1',
        name: 'Annissa Gaskoin',
        sex: 'Female',
        birth_date: '24/08/2022',
        birth_place: 'Philippines',
    },
    {
        id: 89,
        avatar: 'https://robohash.org/commodiquamdeleniti.png?size=50x50&set=set1',
        name: 'Gerladina Dadswell',
        sex: 'Female',
        birth_date: '03/05/2022',
        birth_place: 'Indonesia',
    },
    {
        id: 90,
        avatar: 'https://robohash.org/voluptatemvelvoluptatibus.png?size=50x50&set=set1',
        name: 'Lusa Spear',
        sex: 'Female',
        birth_date: '19/03/2022',
        birth_place: 'Honduras',
    },
    {
        id: 91,
        avatar: 'https://robohash.org/etautid.png?size=50x50&set=set1',
        name: 'Cob Whipple',
        sex: 'Male',
        birth_date: '03/08/2022',
        birth_place: 'Portugal',
    },
    {
        id: 92,
        avatar: 'https://robohash.org/itaqueenimnostrum.png?size=50x50&set=set1',
        name: 'Raymond Hockell',
        sex: 'Male',
        birth_date: '13/10/2022',
        birth_place: 'Poland',
    },
    {
        id: 93,
        avatar: 'https://robohash.org/quideminciduntrepellat.png?size=50x50&set=set1',
        name: 'Lindon Kitcat',
        sex: 'Male',
        birth_date: '04/12/2022',
        birth_place: 'Cuba',
    },
    {
        id: 94,
        avatar: 'https://robohash.org/doloribusestmodi.png?size=50x50&set=set1',
        name: 'Alie Spellman',
        sex: 'Female',
        birth_date: '16/05/2022',
        birth_place: 'Slovenia',
    },
    {
        id: 95,
        avatar: 'https://robohash.org/veroassumendaquis.png?size=50x50&set=set1',
        name: 'Tove Meakes',
        sex: 'Female',
        birth_date: '28/03/2022',
        birth_place: 'China',
    },
    {
        id: 96,
        avatar: 'https://robohash.org/aspernaturaperiamlaborum.png?size=50x50&set=set1',
        name: 'Koo Gershom',
        sex: 'Female',
        birth_date: '17/05/2022',
        birth_place: 'Norway',
    },
    {
        id: 97,
        avatar: 'https://robohash.org/quidictaconsequatur.png?size=50x50&set=set1',
        name: 'Washington Jirzik',
        sex: 'Male',
        birth_date: '09/03/2022',
        birth_place: 'Philippines',
    },
    {
        id: 98,
        avatar: 'https://robohash.org/doloreacommodi.png?size=50x50&set=set1',
        name: 'Hervey Burnapp',
        sex: 'Male',
        birth_date: '06/04/2022',
        birth_place: 'Philippines',
    },
    {
        id: 99,
        avatar: 'https://robohash.org/delectusmolestiasautem.png?size=50x50&set=set1',
        name: 'Kamilah Baffin',
        sex: 'Female',
        birth_date: '17/09/2022',
        birth_place: 'France',
    },
    {
        id: 100,
        avatar: 'https://robohash.org/nesciuntsimiliquedolor.png?size=50x50&set=set1',
        name: 'Terence Lyste',
        sex: 'Male',
        birth_date: '21/03/2022',
        birth_place: 'Vietnam',
    },
];
function getRandom(arr) {
    const rand = Math.ceil(Math.random() * 2 + 1);
    return arr.slice(rand - 2 > 0 ? rand - 2 : 0, rand);
}

const GROUPS = ['Typography', 'Biologists', 'Web designers', 'Black magicians'];
for (let i = 0; i < data.length; i++) {
    data[i].groups = getRandom(GROUPS);
}

export default data;
